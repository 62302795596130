import './Schedule.css';

import React from 'react';

import {
  faBowlFood,
  faCamera,
  faClock,
  faHeart,
  faMugHot,
  faMusic,
  faPodcast,
  faRing,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Header } from '../Components/Header';
import useAPI from '../Helper/useAPI';
import { getLanguage } from '../languages/getLanguage';

interface ISchedule {
  id: number;
  time: string;
  text: string;
  created_at: string;
  updated_at: string;
}

export const Schedule = () => {
  const timeline = [
    {
      time: '13:00',
      text: 'Standesamtliche Trauung',
      icons: faRing,
    },
    {
      time: '13:30',
      text: 'Freie Trauung',
      icons: faHeart,
    },
    {
      time: '15:00',
      text: 'Kaffee',
      icon: faMugHot,
    },
    {
      time: '17:00',
      text: 'Gruppenfotos',
      icon: faCamera,
    },
    {
      time: '18:30',
      text: 'Abendessen',
      icon: faBowlFood,
    },
    {
      time: '20:00',
      text: 'Hochzeitstanz',
      icon: faMusic,
    },
    {
      time: '21:00',
      text: 'Entertainment',
      icon: faPodcast,
    },
    {
      time: '24:00',
      text: 'Mitternachtsbuffet',
      icon: faClock,
    },
  ];
  const d = new Date();
  const today = d.getDate() + '.' + (d.getMonth() + 1) + '.' + d.getFullYear();
  const weddingDay = '2.5.2025';
  const theDay = today === weddingDay;
  const { NavigationStatic, ScheduleStatic } = getLanguage();

  return (
    <div className="inner">
      <Header
        image="/img/us/tagesablauf.jpg"
        title={NavigationStatic.schedule}
      />
      <div className="timeline">
        <ul className="tl">
          {timeline
            ?.map((e, idx) => {
              if (!theDay) return { ...e, active: false, previous: false };
              const t = (n: string) => parseInt(`${n}`.replace(':', ''), 10);
              const curr = t(`${d.getHours()}:${d.getMinutes()}`);
              const to_time = timeline.at(idx + 1) ?? { time: '00:00' };

              const from = t(e.time) < curr;
              const to = t(to_time.time) > curr;

              return {
                ...e,
                previous: idx < timeline.length && from && t(e.time) > 1000,
                active: idx < timeline.length && from && to,
              };
            })
            .map((e) => (
              <li
                className={e.active ? 'active' : e.previous ? 'previous' : ''}
                key={e.time}
              >
                <small>{e.time}</small>{' '}
                <span className="icon">
                  <span className="singleicon">
                    {e.icon ? <FontAwesomeIcon icon={e.icon} /> : <></>}
                  </span>
                  {e.icons ? (
                    <span className="icons">
                      <FontAwesomeIcon icon={e.icons} />
                      <FontAwesomeIcon icon={e.icons} />
                    </span>
                  ) : (
                    <></>
                  )}
                </span>
                <span className="text">{e.text}</span>
              </li>
            ))}
        </ul>
        {/* <small>{ScheduleStatic.info}</small> */}
      </div>
    </div>
  );
};
