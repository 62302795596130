import './Menu.css';

import axios from 'axios';
import React, { useEffect, useState } from 'react';

import {
  faBone,
  faBowlRice,
  faCookie,
  faCow,
  faDrumstickBite,
  faGlassWater,
  faIceCream,
  faLeaf,
  faMugHot,
  faPlateWheat,
  faSeedling,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Header } from '../Components/Header';
import API from '../Helper/API';
import useAPI from '../Helper/useAPI';
import { getLanguage } from '../languages/getLanguage';

interface IFoodplan {
  id: number;
  name: string;
  category: string;
  main: string;
  menu: string;
  info: string;
  created_at: string;
  updated_at: string;
}

export function Menu() {
  const foodplan: {
    options: {
      name: string;
      info?: string | string[];
      note?: string;
      icon?: IconDefinition;
    }[];
    note?: string;
  }[] = [
    {
      options: [
        {
          name: 'Fränkische Hochzeitssuppe',
          info: 'mit selbstgemachten Semmel- und Biscuitklößchen',
          icon: faBowlRice,
        },
      ],
    },
    {
      options: [
        {
          name: 'Salatvariation',
          info: [
            'mit dreierlei Hausmachersalaten und Blattsalatbouquet',
            'an Joghurtdressing serviert mit honigglasiertem Hähnchen',
          ],
          icon: faSeedling,
        },
      ],
    },
    {
      options: [
        {
          name: 'Schäuferle',
          info: 'in Bratenjus mit Klößen',
          icon: faBone,
        },
        {
          name: 'Schweinefilet',
          info: 'mit Champingnonrahmsauce und Kroketten',
          icon: faCow,
        },
        {
          name: 'Spinatlasagne',
          info: 'mit Bechamel-sauce, Cherrytomaten & Fetakäse',
          note: 'Vegetarisch',
          icon: faLeaf,
        },
      ],
      note: 'Wähle 1',
    },
    {
      options: [
        {
          name: 'Lauwarmes Schokotörtchen',
          info: 'mit flüssigem Kern',
          icon: faMugHot,
        },
        {
          name: 'Frischer Obstsalat',
          icon: faPlateWheat,
        },
        {
          name: 'Panna Cotta',
          info: 'mit Erdbeerspiegel',
          icon: faGlassWater,
        },
        {
          name: 'Kugel Eis',
          info: 'mit Waffel',
          icon: faIceCream,
        },
      ],
      note: 'Flying Buffet',
    },
  ];

  const { NavigationStatic, MenuStatic } = getLanguage();

  return (
    <div className="inner">
      <Header image="/img/us/food.jpg" title={NavigationStatic.menu} />
      <div className="menu photos">
        {foodplan.map((gang, idx) => {
          return (
            <div className="gang" key={idx}>
              <div className="title">
                {idx + 1}. Gang
                {gang.note ? <small> ({gang.note})</small> : <></>}
              </div>
              <div className="items">
                {gang.options.map((option) => {
                  return (
                    <div className="item" key={option.name}>
                      {option.icon ? (
                        <FontAwesomeIcon
                          icon={option.icon}
                          style={{ marginRight: 5 }}
                        />
                      ) : (
                        <></>
                      )}
                      <b>{option.name}</b>{' '}
                      <span>
                        {typeof option.info === 'object'
                          ? option.info.map((e) => <span key={e}>{e}</span>)
                          : option.info || ''}
                      </span>
                      {option.note ? <small>{option.note}</small> : <></>}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
