import './Home.css';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';

import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Header } from '../Components/Header';
import { days } from '../Helper/Days';
import { getLanguage } from '../languages/getLanguage';

export function Home() {
  const { HomeStatic, NavigationStatic } = getLanguage();

  const [mosaic, _setMosaic] = useState([
    {
      highlight: false,
      enabled: true,
      link: '/anfahrt',
      background: '/img/bmw-1045051_1920.jpg',
      name: NavigationStatic.anfahrt,
    },
    {
      highlight: false,
      enabled: false,
      link: '/menu',
      background: '/img/us/food.jpg',
      name: NavigationStatic.menu,
    },
    {
      highlight: false,
      enabled: true,
      link: '/schedule',
      background: '/img/us/tagesablauf.jpg',
      name: NavigationStatic.schedule,
    },
    {
      highlight: false,
      enabled: true,
      link: '/contact',
      background: '/img/us/kontakt.jpg',
      name: NavigationStatic.contact,
    },
    {
      highlight: false,
      enabled: false,
      link: '/photos',
      background: '/img/us/DSC00651.jpg',
      name: NavigationStatic.photos,
    },
    {
      highlight: true,
      enabled: true,
      link: '/rsvp',
      background: '/img/us/RSVP_2.jpg',
      name: NavigationStatic.rsvp,
    },
  ]);

  const [daysLeft, setDaysLeft] = useState(
    days(new Date(2025, 5, 2), new Date()),
  );
  const [names, setNames] = useState(
    HomeStatic.names.entries.map((name, idx) => ({
      active: idx === 0,
      key: name,
      text: name,
    })),
  );
  const [dateClass, setDateClass] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setDateClass('active');
    }, 1 * 1000);
  }, []);

  useEffect(() => {
    setTimeout(
      () => {
        if (names[1].active) {
          setNames([names[1], names[2], names[3], names[0]]);
        } else {
          setNames([
            { ...names[0], active: false },
            { ...names[1], active: true },
            names[2],
            names[3],
          ]);
        }
      },
      (names[1].active ? HomeStatic.names.timeout - 1 : 1) * 1000,
    );
    setDaysLeft(
      days(
        new Date(
          HomeStatic.date.year,
          HomeStatic.date.month - 1,
          HomeStatic.date.day,
        ),
        new Date(),
      ),
    );
  }, [names]);

  return (
    <div className="inner">
      <Header image={HomeStatic.background} title={''} />
      <div className="home">
        <h1>
          {names.map((e) => (
            <span className={e.active ? 'active' : ''} key={e.text}>
              {e.text}
            </span>
          ))}
        </h1>
        <h3 className="days-left">
          <FontAwesomeIcon icon={faHeart} className="heart" />
          <span>
            {daysLeft}
            <small>{HomeStatic.texts.days_text}</small>
          </span>
        </h3>
        <h2>
          {reactStringReplace(HomeStatic.texts.info, /~(\w+)~/, (match) => (
            <span className="fnt" key={match}>
              {match}
            </span>
          ))}
        </h2>
        <div className="naviMosaic row">
          <div className="col-md-2"></div>
          <Link
            to={'https://mywishlists.de/wishs/view/00stspGrU7JKQKhHv5Y5bRzj'}
            target="_blank"
            style={{
              aspectRatio: 64 / 9,
            }}
            className={`item col-md-12`}
          >
            <div
              className="item-background"
              style={{
                backgroundImage: `url(img/dandelion-1622100_1920.jpg)`,
                backgroundPositionY: 'top',
              }}
            >
              <span className="item-name">Wunschliste</span>
            </div>
          </Link>
        </div>
        <div className="naviMosaic row">
          {mosaic
            .filter((e) => e.enabled)
            .map((e) => (
              <Link
                to={e.link}
                key={e.name}
                className={`item col-md-${e.highlight ? '12' : '4'}`}
              >
                <div
                  className="item-background"
                  style={{ backgroundImage: `url(${e.background})` }}
                >
                  <span className="item-name">{e.name}</span>
                </div>
              </Link>
            ))}
        </div>
        <div className={`overlayDate ${dateClass}`}>
          <h2>
            {new Date(
              HomeStatic.date.year,
              HomeStatic.date.month - 1,
              HomeStatic.date.day,
            ).toLocaleDateString()}
          </h2>
          <h3>{HomeStatic.texts.save_the_date}</h3>
        </div>
        <span className="hashtag">
          {HomeStatic.texts.hashtag} | Made with{' '}
          <FontAwesomeIcon icon={faHeart} /> | Version 1.4.0
        </span>
      </div>
    </div>
  );
}
